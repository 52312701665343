import styles from '@/styles/components/Header.module.sass'
import MenuItem from '@/models/menuItem'
import Link from 'next/link'
import {
    useEffect,
    useState,
} from 'react'
import {FaBars} from 'react-icons/fa'
import cn from 'classnames'

const Header = ({isWhite = false}: {
    isWhite?: boolean
}) => {

    const menuItems: MenuItem[] = [
        {
            name: 'Accueil',
            href: '/',
        },
        {
            name: 'À propos',
            href: '/a-propos',
        },
        {
            name: 'Services',
            href: '/services',
        },
        {
            name: 'Réalisations',
            href: '/realisations',
        },
        {
            name: 'Contact',
            href: '/contact',
        },
    ]

    //region Window size is mobile
    const [isMobile, setIsMobile] = useState(false)
    // Menu opened
    const [menuOpened, setMenuOpened] = useState(false)
    useEffect(() => {
        // Set is mobile
        setIsMobile(window.innerWidth <
            1024)

        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth <
                1024)
        })
    }, [])

    useEffect(() => {
        document.body.style.overflow =
            menuOpened ?
                'hidden' :
                'auto'
    }, [menuOpened])
    //endregion

    return (
        <header className={cn(styles.header, {[styles.white]: isWhite})}>
            {/*Home link*/}
            <Link
                href={'/'}
                scroll={false}
            >
                <svg
                    width='328'
                    height='44'
                    viewBox='0 0 328 44'
                    xmlns='http://www.w3.org/2000/svg'

                    className={styles.logo}
                >
                    <path
                        d='M42.8427 0L35.7475 42.5333H27.9427L13.8566 15.7631H13.5227L9.03599 42.5333H0L7.09523 0H15.0252L29.0487 26.7495H29.4035L33.8484 0H42.8427Z'
                    />
                    <path
                        d='M43.2131 42.5333L50.3083 0H79.1066L77.8545 7.41426H58.0922L56.4227 17.5492H74.7034L73.4513 24.9634H55.1706L53.5012 35.1191H73.347L72.0949 42.5333H43.2131Z'
                    />
                    <path
                        d='M94.4709 0L100.648 14.4962H100.982L112.084 0H122.581L106.032 21.2667L115.777 42.5333H105.573L99.2289 28.0163H98.895L87.7096 42.5333H77.1711L94.137 21.2667L84.5167 0H94.4709Z'
                    />
                    <path
                        d='M125.419 7.41426L126.671 0H161.771L160.519 7.41426H147.435L141.592 42.5333H132.66L138.503 7.41426H125.419Z'
                    />
                    <path
                        d='M187.969 44C185.591 44 183.525 43.5098 181.771 42.5294C180.017 41.549 178.73 40.6471 177.912 39.8235C177.522 39.4314 177.19 39.1373 176.917 38.9412C176.645 38.7451 176.391 38.6471 176.157 38.6471C175.533 38.6471 174.949 39.1961 174.403 40.2941C173.857 41.3529 173.428 42.4314 173.116 43.5294H171.771L176.45 24.9412H177.795C177.6 26.1176 177.502 27.1569 177.502 28.0588C177.502 30.5294 177.989 32.8431 178.964 35C179.978 37.1569 181.323 38.8824 182.999 40.1765C184.675 41.4706 186.488 42.1176 188.437 42.1176C191.712 42.1176 194.207 41.4314 195.922 40.0588C197.638 38.6863 198.495 36.6667 198.495 34C198.495 32.4314 198.105 31.1569 197.326 30.1765C196.546 29.1569 195.357 28.1569 193.759 27.1765L186.975 23C184.597 21.5882 182.863 20.098 181.771 18.5294C180.679 16.9608 180.134 14.902 180.134 12.3529C180.134 8.23529 181.303 5.15686 183.642 3.11765C186.02 1.03922 189.12 0 192.94 0C194.343 0 195.727 0.372549 197.092 1.11765C198.495 1.86274 199.626 2.62745 200.483 3.41176C200.873 3.7647 201.185 4.01961 201.419 4.17647C201.653 4.29412 201.906 4.35294 202.179 4.35294C202.842 4.35294 203.446 3.92157 203.992 3.05882C204.538 2.19608 204.928 1.33333 205.162 0.470586H206.507L203.115 15.7647H201.77C201.887 13.8824 201.536 11.8431 200.717 9.64706C199.938 7.45098 198.807 5.60784 197.326 4.11765C195.844 2.62745 194.207 1.88235 192.414 1.88235C190.425 1.88235 188.73 2.2549 187.326 3C185.962 3.7451 184.948 4.72549 184.285 5.94118C183.623 7.11765 183.291 8.37255 183.291 9.70588C183.291 11.549 183.818 13.0196 184.87 14.1176C185.962 15.2157 187.424 16.2941 189.256 17.3529L194.753 20.5882C196.546 21.6863 197.949 22.6863 198.963 23.5882C200.016 24.451 200.815 25.5098 201.361 26.7647C201.945 27.9804 202.238 29.4902 202.238 31.2941C202.238 35.2157 200.99 38.3137 198.495 40.5882C196 42.8627 192.492 44 187.969 44Z'
                    />
                    <path
                        d='M212.712 41.0588H218.56C219.573 41.0588 220.216 40.5294 220.489 39.4706L229.904 4.52941C229.982 4.13725 230.021 3.90196 230.021 3.82353C230.021 3.23529 229.631 2.94118 228.851 2.94118H226.922C225.596 2.94118 224.329 3.27451 223.121 3.94117C221.951 4.60784 220.977 5.31372 220.197 6.05882C219.066 7.15686 218.053 8.5098 217.156 10.1176C216.298 11.6863 215.597 13.1765 215.051 14.5882C214.544 15.9608 214.291 16.7059 214.291 16.8235H212.829L217.507 1.05882H249.552L245.868 16.8235H244.406C244.445 16.6667 244.504 16.3137 244.582 15.7647C244.699 15.2157 244.815 14.4706 244.932 13.5294C245.049 12.5882 245.108 11.6471 245.108 10.7059C245.108 7.80392 244.504 5.78431 243.295 4.64706C242.126 3.5098 240.781 2.94118 239.26 2.94118H237.33C236.161 2.94118 235.459 3.47059 235.225 4.52941L225.811 39.4706C225.772 39.6275 225.752 39.8431 225.752 40.1176C225.752 40.4314 225.83 40.6667 225.986 40.8235C226.181 40.9804 226.473 41.0588 226.863 41.0588H232.711L232.243 42.9412H212.244L212.712 41.0588Z'
                    />
                    <path
                        d='M246.302 41.0588H250.747C251.799 41.0588 252.462 40.5294 252.735 39.4706L262.15 4.52941C262.228 4.21569 262.267 3.98039 262.267 3.82353C262.267 3.23529 261.857 2.94118 261.039 2.94118H256.594L257.062 1.05882H289.341L286.651 14.4706H285.189C285.228 14.3137 285.267 14 285.306 13.5294C285.345 13.0196 285.365 12.3922 285.365 11.6471C285.365 8.35294 284.839 6.07843 283.786 4.82353C282.733 3.56863 281.154 2.94118 279.049 2.94118H269.459C268.407 2.94118 267.744 3.47059 267.471 4.52941L263.202 20.3529H269.518C271.038 20.3529 272.266 19.9412 273.202 19.1176C274.137 18.2549 274.82 17.2549 275.248 16.1176C275.716 14.9804 276.164 13.6078 276.593 12H278.055L273.026 31.0588H271.564C271.954 29.3333 272.149 27.8627 272.149 26.6471C272.149 25.2745 271.876 24.1961 271.33 23.4118C270.824 22.6274 269.927 22.2353 268.641 22.2353H262.734L258.056 39.4706C257.978 39.7843 257.939 40 257.939 40.1176C257.939 40.7451 258.349 41.0588 259.167 41.0588H270.044C271.058 41.0588 272.247 40.8235 273.611 40.3529C274.975 39.8431 276.203 39.0588 277.295 38C278.387 36.902 279.381 35.5686 280.277 34C281.213 32.3922 281.954 30.9412 282.499 29.6471C283.045 28.3529 283.377 27.5294 283.494 27.1765H284.897L279.985 42.9412H245.835L246.302 41.0588Z'
                    />
                    <path
                        d='M287.247 41.0588H291.692C292.744 41.0588 293.407 40.5294 293.68 39.4706L303.095 4.52941L303.212 3.82353C303.212 3.23529 302.802 2.94118 301.984 2.94118H297.539L298.066 1.05882H318.006C320.891 1.05882 323.23 1.88235 325.023 3.52941C326.817 5.13726 327.713 7.41177 327.713 10.3529C327.713 12.7059 326.992 14.902 325.55 16.9412C324.146 18.9412 322.119 20.5686 319.468 21.8235C316.856 23.0392 313.835 23.6471 310.404 23.6471H303.27L299.001 39.4706C298.962 39.6275 298.943 39.8431 298.943 40.1176C298.943 40.4706 299.021 40.7255 299.177 40.8824C299.372 41 299.683 41.0588 300.112 41.0588H306.662L306.194 42.9412H286.78L287.247 41.0588ZM311.632 21.7647C313.153 21.7647 314.614 21.3922 316.018 20.6471C317.46 19.902 318.669 18.8627 319.643 17.5294C320.423 16.4314 321.027 15.1569 321.456 13.7059C321.885 12.2157 322.099 10.7451 322.099 9.29412C322.099 7.13725 321.573 5.54902 320.521 4.52941C319.507 3.47059 318.24 2.94118 316.72 2.94118H310.404C309.352 2.94118 308.689 3.47059 308.416 4.52941L303.796 21.7647H311.632Z'
                    />
                </svg>
            </Link>

            <nav className={styles.menu_container}>
                <FaBars
                    className={styles.icon}

                    onClick={() => {
                        if (isMobile) {
                            setMenuOpened(!menuOpened)
                        }
                    }}
                />

                <div
                    className={cn(styles.menu_wrapper, {[styles.opened]: menuOpened})}

                    onClick={(e) => {
                        if (e.currentTarget ===
                            e.target) {
                            setMenuOpened(false)
                        }
                    }}
                >
                    <ul className={styles.list}>
                        {menuItems.map(menuItem =>
                            (
                                <li
                                    key={menuItem.name}
                                    className={styles.menu_item}
                                >
                                    <Link
                                        href={menuItem.href}
                                        className={styles.link}
                                        scroll={false}
                                    >
                                        {menuItem.name}
                                    </Link>
                                </li>
                            ),
                        )}
                    </ul>
                </div>
            </nav>
        </header>
    )
}
export default Header
