import styles from '@/styles/components/Footer.module.sass'
import Link from 'next/link'
import MenuItem from '@/models/menuItem'
import cn from 'classnames'
import Markdown from "marked-react";

export type FooterData = {
    /*Others*/
    instagram_link: string

    /*Contact*/
    address: string
    phone: string
    email: string
}

const Footer = ({
                    data,
                    invertedColors = false,
                }: {
    data: FooterData,
    invertedColors?: boolean
}) => {

    const menuItems: MenuItem[] = [
        {
            name: 'Accueil',
            href: '/',
        },
        {
            name: 'À propos',
            href: '/a-propos',
        },
        {
            name: 'Services',
            href: '/services',
        },
        {
            name: 'Réalisations',
            href: '/realisations',
        },
        {
            name: 'Contact',
            href: '/contact',
        },
    ]

    const otherLinks: MenuItem[] = [
        {
            name: 'Instagram',
            href: data.instagram_link,
            target: '_blank',
        },
        {
            name: 'Mentions légales',
            href: '/mentions-legales',
        },
        {
            name: 'Politique de confidentialité',
            href: '/politique-de-confidentialite',
        },
    ]

    return (
        <footer className={cn(styles.footer, {[styles.inverted_colors]: invertedColors})}>
            {/*Content*/}
            <div className={styles.footer_content}>
                {/*Logo*/}
                <Link
                    href={'/'}
                    className={styles.logo}
                >
                    <svg
                        width='658'
                        height='369'
                        viewBox='0 0 658 369'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M174.243 0L145.386 173.818H113.644L56.3551 64.418H54.9972L36.7496 173.818H0L28.8565 0H61.108L118.142 109.315H119.585L137.663 0H174.243Z'
                        />
                        <path
                            d='M175.749 173.818L204.606 0H321.729L316.637 30.2994H236.263L229.473 71.717H303.821L298.729 102.016H224.381L217.591 143.519H298.304L293.212 173.818H175.749Z'
                        />
                        <path
                            d='M384.216 0L409.338 59.2408H410.696L455.848 0H498.539L431.235 86.9091L470.871 173.818H429.368L403.567 114.493H402.209L356.718 173.818H313.857L382.858 86.9091L343.732 0H384.216Z'
                        />
                        <path
                            d='M510.082 30.2994L515.174 0H657.929L652.837 30.2994H599.622L575.858 173.818H539.532L563.297 30.2994H510.082Z'
                        />
                        <path
                            d='M66.203 368.59C56.4837 368.59 48.039 366.599 40.869 362.615C33.699 358.632 28.441 354.967 25.095 351.621C23.5017 350.028 22.1473 348.833 21.032 348.036C19.9167 347.24 18.881 346.841 17.925 346.841C15.3757 346.841 12.9857 349.072 10.755 353.533C8.52433 357.835 6.77167 362.217 5.497 366.678H0L19.12 291.154H24.617C23.8203 295.934 23.422 300.157 23.422 303.821C23.422 313.859 25.4137 323.26 29.397 332.023C33.5397 340.787 39.0367 347.797 45.888 353.055C52.7393 358.313 60.1483 360.942 68.115 360.942C81.499 360.942 91.6963 358.154 98.707 352.577C105.718 347.001 109.223 338.795 109.223 327.96C109.223 321.587 107.63 316.409 104.443 312.425C101.256 308.283 96.3967 304.22 89.864 300.236L62.14 283.267C52.4207 277.531 45.3303 271.477 40.869 265.103C36.4077 258.73 34.177 250.365 34.177 240.008C34.177 223.278 38.957 210.771 48.517 202.485C58.2363 194.041 70.9033 189.818 86.518 189.818C92.254 189.818 97.9103 191.332 103.487 194.359C109.223 197.387 113.844 200.494 117.349 203.68C118.942 205.114 120.217 206.15 121.173 206.787C122.129 207.265 123.165 207.504 124.28 207.504C126.989 207.504 129.458 205.752 131.689 202.246C133.92 198.741 135.513 195.236 136.469 191.73H141.966L128.104 253.87H122.607C123.085 246.222 121.651 237.937 118.305 229.014C115.118 220.092 110.498 212.603 104.443 206.548C98.3883 200.494 91.6963 197.466 84.367 197.466C76.241 197.466 69.31 198.98 63.574 202.007C57.9973 205.035 53.8547 209.018 51.146 213.957C48.4373 218.737 47.083 223.836 47.083 229.253C47.083 236.742 49.234 242.717 53.536 247.178C57.9973 251.64 63.9723 256.021 71.461 260.323L93.927 273.468C101.256 277.93 106.992 281.993 111.135 285.657C115.437 289.163 118.703 293.465 120.934 298.563C123.324 303.503 124.519 309.637 124.519 316.966C124.519 332.9 119.42 345.487 109.223 354.728C99.0257 363.97 84.6857 368.59 66.203 368.59Z'
                        />
                        <path
                            d='M167.328 356.64H191.228C195.371 356.64 198 354.489 199.115 350.187L237.594 208.221C237.913 206.628 238.072 205.672 238.072 205.353C238.072 202.963 236.479 201.768 233.292 201.768H225.405C219.988 201.768 214.809 203.123 209.87 205.831C205.09 208.54 201.107 211.408 197.92 214.435C193.299 218.897 189.157 224.394 185.492 230.926C181.987 237.3 179.119 243.354 176.888 249.09C174.817 254.667 173.781 257.694 173.781 258.172H167.806L186.926 194.12H317.898L302.841 258.172H296.866C297.025 257.535 297.264 256.101 297.583 253.87C298.061 251.64 298.539 248.612 299.017 244.788C299.495 240.964 299.734 237.14 299.734 233.316C299.734 221.526 297.264 213.32 292.325 208.699C287.545 204.079 282.048 201.768 275.834 201.768H267.947C263.167 201.768 260.299 203.919 259.343 208.221L220.864 350.187C220.705 350.825 220.625 351.701 220.625 352.816C220.625 354.091 220.944 355.047 221.581 355.684C222.378 356.322 223.573 356.64 225.166 356.64H249.066L247.154 364.288H165.416L167.328 356.64Z'
                        />
                        <path
                            d='M304.617 356.64H322.781C327.083 356.64 329.791 354.489 330.907 350.187L369.386 208.221C369.704 206.947 369.864 205.991 369.864 205.353C369.864 202.963 368.191 201.768 364.845 201.768H346.681L348.593 194.12H480.521L469.527 248.612H463.552C463.711 247.975 463.87 246.7 464.03 244.788C464.189 242.717 464.269 240.168 464.269 237.14C464.269 223.756 462.118 214.515 457.816 209.416C453.514 204.318 447.061 201.768 438.457 201.768H399.261C394.959 201.768 392.25 203.919 391.135 208.221L373.688 272.512H399.5C405.714 272.512 410.733 270.839 414.557 267.493C418.381 263.988 421.169 259.925 422.922 255.304C424.834 250.684 426.666 245.107 428.419 238.574H434.394L413.84 316.01H407.865C409.458 309 410.255 303.025 410.255 298.085C410.255 292.509 409.139 288.127 406.909 284.94C404.837 281.754 401.173 280.16 395.915 280.16H371.776L352.656 350.187C352.337 351.462 352.178 352.338 352.178 352.816C352.178 355.366 353.851 356.64 357.197 356.64H401.651C405.793 356.64 410.653 355.684 416.23 353.772C421.806 351.701 426.825 348.514 431.287 344.212C435.748 339.751 439.811 334.334 443.476 327.96C447.3 321.428 450.327 315.532 452.558 310.274C454.788 305.016 456.143 301.67 456.621 300.236H462.357L442.281 364.288H302.705L304.617 356.64Z'
                        />
                        <path
                            d='M471.963 356.64H490.127C494.429 356.64 497.138 354.489 498.253 350.187L536.732 208.221L537.21 205.353C537.21 202.963 535.537 201.768 532.191 201.768H514.027L516.178 194.12H597.677C609.468 194.12 619.028 197.466 626.357 204.158C633.687 210.691 637.351 219.932 637.351 231.882C637.351 241.442 634.404 250.365 628.508 258.65C622.772 266.776 614.487 273.389 603.652 278.487C592.977 283.427 580.629 285.896 566.607 285.896H537.449L520.002 350.187C519.843 350.825 519.763 351.701 519.763 352.816C519.763 354.25 520.082 355.286 520.719 355.923C521.516 356.401 522.791 356.64 524.543 356.64H551.311L549.399 364.288H470.051L471.963 356.64ZM571.626 278.248C577.84 278.248 583.815 276.735 589.551 273.707C595.447 270.68 600.386 266.458 604.369 261.04C607.556 256.579 610.026 251.401 611.778 245.505C613.531 239.451 614.407 233.476 614.407 227.58C614.407 218.817 612.256 212.364 607.954 208.221C603.812 203.919 598.633 201.768 592.419 201.768H566.607C562.305 201.768 559.597 203.919 558.481 208.221L539.6 278.248H571.626Z'
                        />
                    </svg>
                </Link>

                {/*Contact*/}
                <div className={styles.footer_contact}>
                    {/*Title*/}
                    <span className={styles.title}>Contact</span>

                    {/*Links*/}
                    <div className={styles.links}>
                        {/*Address*/}
                        <div className={styles.address}>
                            <Markdown
                                value={data.address}
                                breaks={true}
                            />
                        </div>

                        {/*Email*/}
                        <Link
                            href={`mailto:${data.email}`}
                            className={styles.email}
                        >{data.email}</Link>

                        {/*Phone number*/}
                        {/*TODO: Verify phone number*/}
                        <Link
                            href={`tel:${data.phone}`}
                            className={styles.phone}
                            scroll={false}
                        >{data.phone}</Link>
                    </div>
                </div>

                {/*Menu*/}
                <div className={styles.footer_menu}>
                    {/*Title*/}
                    <span className={styles.title}>Menu</span>

                    {/*Links*/}
                    <ul className={styles.links}>
                        {menuItems.map(menuItem =>
                            (
                                <li
                                    key={menuItem.name}
                                    className={styles.item}
                                >
                                    <Link
                                        href={menuItem.href}
                                        className={styles.link}
                                        scroll={false}
                                    >
                                        {menuItem.name}
                                    </Link>
                                </li>
                            ),
                        )}
                    </ul>
                </div>

                {/*Other links*/}
                <div className={styles.footer_other_links}>
                    {/*Title*/}
                    <span className={styles.title}>Autres</span>

                    {/*Links*/}
                    <ul className={styles.links}>
                        {otherLinks.map(otherLink =>
                            (
                                <li
                                    key={otherLink.name}
                                    className={styles.item}
                                >
                                    <Link
                                        href={otherLink.href}
                                        className={styles.link}
                                        scroll={false}
                                        target={otherLink.target}
                                    >
                                        {otherLink.name}
                                    </Link>
                                </li>
                            ))}
                    </ul>
                </div>

                {/*Bottom*/}
                <div className={styles.footer_bottom}>
                    {/*Copyright with current year*/}
                    <span className={styles.copyright}>&copy; {new Date().getFullYear()} Next Step. Tous droits réservés</span>

                    {/*Realised by Ziben*/}
                    <span className={styles.footer_bottom_ziben}>Site réalisé par <Link
                        href={'https://www.ziben.fr'}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >Ziben</Link></span>
                </div>
            </div>
        </footer>
    )
}

export default Footer
